<!--  -->
<template>
  <div>
    <a-card title="系统参数列表">
      <div class="tool">
        <div>
          <a-input-search
            v-model:value="keyword"
            placeholder="请输入关键词"
            enter-button
            @search="onSearch"
            @change.stop="onSearchChange"
          />
        </div>
        <div>
          <a-button
            type="primary"
            @click="handleAdd"
            style="margin-bottom: 8px"
            :disabled="addBtnDisabled"
          >
            <PlusOutlined />添加
          </a-button>
        </div>
      </div>
      <div>
        <a-table
          bordered
          :data-source="paramList"
          :columns="columns"
          :pagination="false"
          :rowKey="(record) => record.id"
        >
          <template
            v-for="col in ['paramName', 'paramCode', 'paramValue']"
            #[col]="{ text, record }"
            :key="col"
          >
            <div>
              <a-input
                v-if="editableData[record.id]"
                v-model:value="editableData[record.id][col]"
                style="margin: -5px 0"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
          <template #operation="{ record }">
            <div>
              <span v-if="editableData[record.id]">
                <a-button type="link" @click.stop="handleSave(record.id)"
                  ><SaveOutlined />保存</a-button
                >
                <a-popconfirm
                  title="您确定要取消编辑吗?"
                  okText="确定"
                  cancelText="取消"
                  @confirm="handleCancel(record.id)"
                >
                  <a-button type="link"><UndoOutlined />取消</a-button>
                </a-popconfirm>
              </span>
              <span v-else>
                <a-button type="link" @click.stop="handleEdit(record.id)"
                  ><EditOutlined />编辑</a-button
                >
                <a-popconfirm
                  title="您确信要执行删除吗?"
                  okText="确定"
                  cancelText="取消"
                  @confirm="handleDelete(record.id)"
                >
                  <a-button type="link"><DeleteOutlined />删除</a-button>
                </a-popconfirm>
              </span>
            </div>
          </template>
        </a-table>
        <div class="pagination">
          <a-pagination
            :current="pagination.pageIndex"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            :page-size-options="pagination.pageSizeOptions"
            :showTotal="(total) => `共 ${total} 条记录`"
            show-size-changer
            @change="onChangePage"
            @showSizeChange="onShowSizeChange"
          >
            <template #buildOptionText="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, defineComponent } from "vue";
import {
  PlusOutlined,
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  UndoOutlined,
} from "@ant-design/icons-vue";
import { cloneDeep } from "lodash-es";
import { getParamListPage, saveParam, deleteParam } from "@/api/system/param";
export default defineComponent({
  name: "Param",
  components: {
    PlusOutlined,
    EditOutlined,
    SaveOutlined,
    DeleteOutlined,
    UndoOutlined,
  },
  setup() {
    const state = reactive({
      loading: false,
      addBtnDisabled: false,
      keyword: "",
      columns: [
        {
          title: "参数名称",
          dataIndex: "paramName",
          width: "30%",
          slots: {
            customRender: "paramName",
          },
        },
        {
          title: "参数编码",
          dataIndex: "paramCode",
          width: "20%",
          slots: {
            customRender: "paramCode",
          },
          sorter: (a, b) => {
            if (a.paramCode < b.paramCode) {
              return -1;
            }
            if (a.paramCode > b.paramCode) {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "参数值",
          dataIndex: "paramValue",
          width: "20%",
          slots: {
            customRender: "paramValue",
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: {
            customRender: "operation",
          },
        },
      ],
      paramList: [],
      searchParam: {
        searchValue: "",
        pageIndex: 1,
        pageSize: 5,
      },
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 5, // 默认每页显示数量
        pageSizeOptions: ["5", "10", "20", "50"], // 每页数量选项
        total: 0, //总条数
      },
    });
    onMounted(() => {
      initParamList(state.searchParam);
    });
    const onSearch = () => {
      state.searchParam = {
        searchValue: state.keyword,
        pageIndex: 1,
        pageSize: 5,
      };
      initParamList(state.searchParam);
    };
    const onSearchChange = () => {
      state.searchParam.searchValue = state.keyword;
    };
    const onChangePage = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initParamList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initParamList(state.searchParam);
    };
    const handleEdit = (key) => {
      state.addBtnDisabled = true;
      editableData[key] = cloneDeep(
        state.paramList.filter((item) => key === item.id)[0]
      );
    };
    const handleAdd = () => {
      state.addBtnDisabled = true;
      const newData = {
        id: 9999,
        paramName: null,
        paramCode: null,
        paramValue: null,
      };
      state.paramList.push(newData);
      editableData[9999] = cloneDeep(
        state.paramList.filter((item) => item.id === 9999)[0]
      );
    };
    const handleCancel = (key) => {
      state.addBtnDisabled = false;
      delete editableData[key];
      if (key === 9999) {
        state.paramList = state.paramList.filter((item) => item.id != 9999);
      }
    };
    const handleSave = (key) => {
      saveParam(editableData[key]).then((resp) => {
        if (resp.code == 200) {
          Object.assign(
            state.paramList.filter((item) => key === item.id)[0],
            editableData[key]
          );
          if (state.keyword) {
            state.searchParam.searchValue = state.keyword;
          }
          let total = state.pagination.total + 1;
          let lastPage = Math.ceil(total / state.pagination.pageSize);
          state.pagination.pageIndex = lastPage;
          state.searchParam.pageIndex = lastPage;
          initParamList(state.searchParam);
          state.addBtnDisabled = false;
          delete editableData[key];
        }
      });
    };
    const handleDelete = (key) => {
      deleteParam({ id: key, logic: false }).then((resp) => {
        if (resp.code == 200) {
          let total = state.pagination.total - 1;
          let lastPage = Math.ceil(total / state.pagination.pageSize);
          if (lastPage < state.pagination.pageIndex) {
            state.pagination.pageIndex = lastPage;
            state.searchParam.pageIndex = lastPage;
          }
          initParamList(state.searchParam);
        }
      });
    };
    const initParamList = (searchParam) => {
      state.loading = true;
      getParamListPage(searchParam).then((resp) => {
        if (resp) {
          state.paramList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const editableData = reactive({});
    const refData = toRefs(state);
    return {
      ...refData,
      onSearch,
      onSearchChange,
      onChangePage,
      onShowSizeChange,
      editableData,
      handleAdd,
      handleEdit,
      handleSave,
      handleCancel,
      handleDelete,
      initParamList,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}

.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>