import { postRequest, putRequest, deleteRequest } from '../request'

export const getParamListPage = (res) => {
    return postRequest('/system/param/params', res)
}

export const saveParam = (res) => {
    if (res.id === 9999) {
        //添加系统参数
        return postRequest('/system/param', res);
    }
    else {
        //更新系统参数
        return putRequest('/system/param', res);
    }
}

export const deleteParam = (res) => {
    return deleteRequest('/system/param/' + res.id + "?logic=" + res.logic)
}